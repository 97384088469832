/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateFibIndex = /* GraphQL */ `
  subscription OnCreateFibIndex {
    onCreateFibIndex {
      id
      index
      owner
      value
    }
  }
`;
export const onUpdateFibIndex = /* GraphQL */ `
  subscription OnUpdateFibIndex {
    onUpdateFibIndex {
      id
      index
      owner
      value
    }
  }
`;
export const onDeleteFibIndex = /* GraphQL */ `
  subscription OnDeleteFibIndex {
    onDeleteFibIndex {
      id
      index
      owner
      value
    }
  }
`;

import React from "react";
import { Label, ListGroup } from "reactstrap";
import { FibFinderListItem } from "./FibFinderListItem";
import { FibFinderListItemSmall } from "./FibFinderListItemSmall";
import { Flipper } from "react-flip-toolkit";

export const FibFinderList = ({ currentUser, indexes, item, setIndexes }) => {
  const renderIndexes = () =>
    indexes.map((item) => {
      return (
        <FibFinderListItem
          currentUser={currentUser}
          indexes={indexes}
          item={item}
          key={item.index}
          setIndexes={setIndexes}
        />
      );
    });
  const renderSmallIndexes = () =>
    indexes.map((item) => {
      return (
        <FibFinderListItemSmall
          currentUser={currentUser}
          indexes={indexes}
          item={item}
          key={item.index}
          setIndexes={setIndexes}
        />
      );
    });
  return (
    <ListGroup className="animated zoomIn mb-5">
      <div className="d-flex flex-column d-sm-none">
        <Label
          className="mb-1"
          for="indexes-list"
          size="sm"
        >{` Results (${indexes.length})`}</Label>
        <Flipper flipKey={indexes}>{renderSmallIndexes()}</Flipper>
      </div>
      <div className="d-none d-sm-flex flex-column">
        <Label for="indexes-list">{` Results (${indexes.length})`}</Label>
        <Flipper flipKey={indexes}>{renderIndexes()}</Flipper>
      </div>
    </ListGroup>
  );
};

/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createFibIndex = /* GraphQL */ `
  mutation CreateFibIndex(
    $input: CreateFibIndexInput!
    $condition: ModelFibIndexConditionInput
  ) {
    createFibIndex(input: $input, condition: $condition) {
      id
      index
      owner
      value
    }
  }
`;
export const updateFibIndex = /* GraphQL */ `
  mutation UpdateFibIndex(
    $input: UpdateFibIndexInput!
    $condition: ModelFibIndexConditionInput
  ) {
    updateFibIndex(input: $input, condition: $condition) {
      id
      index
      owner
      value
    }
  }
`;
export const deleteFibIndex = /* GraphQL */ `
  mutation DeleteFibIndex(
    $input: DeleteFibIndexInput!
    $condition: ModelFibIndexConditionInput
  ) {
    deleteFibIndex(input: $input, condition: $condition) {
      id
      index
      owner
      value
    }
  }
`;

import React, { useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Spinner
} from "reactstrap";
import Auth from "@aws-amplify/auth";

const SignIn = ({ setCurrentUser }) => {
  const [cognitoUserObj, setCognitoUserObj] = useState(null);
  const [error, setError] = useState("");
  const [passwordMessage, setPasswordMessage] = useState("");
  const [password, setPassword] = useState("");
  const [formStatus, setFormStatus] = useState("signIn");
  const [formUsername, setFormUsername] = useState("");
  const [isSpinning, setIsSpinning] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSpinning(true);
    setError("");
    setPasswordMessage("");
    try {
      let cognitoUser;
      if (formStatus === "signIn") {
        cognitoUser = await Auth.signIn(formUsername, password);
      } else {
        cognitoUser = await Auth.completeNewPassword(cognitoUserObj, password);
      }
      if (cognitoUser.challengeName === "NEW_PASSWORD_REQUIRED") {
        setCognitoUserObj(cognitoUser);
        setFormStatus("newPassword");
        setPassword("");
        setPasswordMessage("Please enter a new password");
        setIsSpinning(false);
      } else {
        setFormUsername("");
        setPassword("");
        setCurrentUser(cognitoUser.username);
        console.log(cognitoUser.username + " has logged in.");
      }
    } catch (e) {
      console.log(e);
      setIsSpinning(false);
      setError(e.message);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div className="container mt-1 mt-sm-3" style={{ width: "300px" }}>
        <div
          className="display-4 text-center mb-3"
          style={{ fontSize: "36px" }}
        >
          Sign In
        </div>
        <FormGroup>
          <Label>Username</Label>
          <Input
            autoComplete="formUsername"
            type="text"
            onChange={(e) => setFormUsername(e.target.value)}
            value={formUsername}
          />
        </FormGroup>
        <FormGroup>
          <Label>Password</Label>
          <Input
            autoComplete="current-password"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
          <small className="text-danger">{passwordMessage}</small>
        </FormGroup>
        <Button type="submit" color="warning" block>
          {isSpinning ? (
            <Spinner type="border" color="dark" size="sm" />
          ) : (
            "Sign In"
          )}
        </Button>
        <FormText color="danger">{error ? error : ""}</FormText>
      </div>
    </Form>
  );
};

export default SignIn;

import React from "react";
import Auth from "@aws-amplify/auth";
import { Button } from "reactstrap";

const SignOut = ({ setCurrentUser }) => {
  const handleSignOut = async () => {
    try {
      await Auth.signOut();
      setCurrentUser("");
      console.log("User has been signed out!");
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <div
        className="d-flex d-sm-none m-2"
        style={{ position: "absolute", top: "0px", right: "0px" }}
      >
        <Button color="warning" onClick={() => handleSignOut()} size="sm">
          Sign Out
        </Button>
      </div>
      <div
        className="d-none d-sm-flex m-3"
        style={{ position: "absolute", top: "0px", right: "0px" }}
      >
        <Button color="warning" onClick={() => handleSignOut()}>
          Sign Out
        </Button>
      </div>
    </>
  );
};

export default SignOut;

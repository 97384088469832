import React, { useEffect, useRef, useState } from "react";
import { Button, Jumbotron } from "reactstrap";
import Amplify from "@aws-amplify/core";
import Auth from "@aws-amplify/auth";
import awsconfig from "./aws-exports";
import logo from "./images/ff-logo-aws.jpg";
import Modal from "./components/Modal";
import FibFinder from "./components/FibFinder";
import Loading from "./components/Loading";
import SignOut from "./components/SignOut";
import SignIn from "./components/SignIn";
import IdleTimer from "react-idle-timer";

Amplify.configure(awsconfig);

const App = () => {
  const idleTimer = useRef(null);

  const onIdle = (event) => {
    Auth.signOut();
    setCurrentUser("");
  };

  const [currentUser, setCurrentUser] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((cognitoUser) => {
        setCurrentUser(cognitoUser.username);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, []);

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className="d-none d-sm-flex align-items-center justify-content-center">
          <Loading />
        </div>
      );
    } else if (currentUser) {
      return <FibFinder currentUser={currentUser} />;
    } else {
      return (
        <>
          <SignIn setCurrentUser={setCurrentUser} />
        </>
      );
    }
  };

  return (
    <div>
      <IdleTimer
        ref={idleTimer}
        element={document}
        onIdle={onIdle}
        debounce={500}
        timeout={1000 * 60 * 30}
      />
      <Modal modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} />
      <Jumbotron className="d-flex flex-column align-items-center text-center py-3 mb-0">
        <div className="d-flex flex-column align-items center justify-content-center text-center d-sm-none">
          <div className="w-100">
            <img
              src={logo}
              alt="fibonacci artwork"
              height="auto"
              width="120px"
            />
          </div>
          {currentUser ? <SignOut setCurrentUser={setCurrentUser} /> : null}
          <div className="display-4 mt-1" style={{ fontSize: "24px" }}>
            Fib Finder 4000
          </div>
          <div className="lead mb-1" style={{ fontSize: "16px" }}>
            Find the value of the Nth position in the Fibonacci sequence{" "}
          </div>
          <Button
            size="sm"
            color="warning"
            data-toggle="modal"
            data-target="#arch-modal"
            onClick={() => setModalIsOpen(true)}
          >
            About the Architecture
          </Button>
        </div>
        <div className="d-none d-sm-flex flex-column">
          <div className="w-100">
            <img
              src={logo}
              alt="fibonacci artwork"
              height="auto"
              width="240px"
            />
          </div>
          {currentUser ? <SignOut setCurrentUser={setCurrentUser} /> : null}
          <div className="display-4 mt-3">Fib Finder 4000</div>
          <div className="lead mb-3">
            Find the value of the Nth position in the Fibonacci sequence{" "}
          </div>
          <Button
            color="warning"
            data-toggle="modal"
            data-target="#arch-modal"
            onClick={() => setModalIsOpen(true)}
          >
            About the Architecture
          </Button>
        </div>
      </Jumbotron>
      {renderContent()}
    </div>
  );
};

export default App;

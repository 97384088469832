import React, { useState } from "react";
import API, { graphqlOperation } from "@aws-amplify/api";
import { ListGroupItem, Spinner } from "reactstrap";
import { Flipped } from "react-flip-toolkit";
import numeral from "numeral";
import * as mutations from "../graphql/mutations";

export const FibFinderListItemSmall = ({
  item,
  indexes,
  setIndexes,
  currentUser
}) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDeleteItem = async (item) => {
    setIsDeleting(true);
    console.log("Deleting item: ", item);
    try {
      const response = await API.graphql(
        graphqlOperation(mutations.deleteFibIndex, {
          input: { id: item.id }
        })
      );
      console.log("Delete response: ", response);
      const cleaned = indexes.filter((el) => el.id !== item.id);
      setIndexes(cleaned);
    } catch (e) {
      setIsDeleting(false);
      console.log(e);
    }
  };

  return (
    <Flipped key={item.index} flipId={item.index}>
      <ListGroupItem
        className="d-flex justify-content-between"
        key={item.id}
        id={item.id}
      >
        <div style={{ fontSize: "14px" }}>
          Fibonacci value at position:{" "}
          <strong style={{ fontSize: "14px" }}>{item.index}</strong> is:{" "}
          {/* <div style={{ fontSize: "14px" }}> */}
          {item.value.toLowerCase() === "calculating..." ? (
            <em
              className="animated infinite pulse"
              style={{ display: "inline-block" }}
            >
              {item.value}
            </em>
          ) : (
            <strong
              className={item.new ? "animated tada" : ""}
              style={{ display: "inline-block" }}
            >
              {numeral(item.value).format()}
            </strong>
          )}
          {/* </div> */}
        </div>
        <div className="d-flex justify-content-center align-items-center text-center">
          {item.value.toLowerCase() === "calculating..." ? (
            <i className="material-icons text-info rotate">hourglass_empty</i>
          ) : isDeleting ? (
            <Spinner type="border" color="danger" size="sm" className="mr-1" />
          ) : item.owner === currentUser ? (
            <i
              className="material-icons text-danger"
              onClick={() => handleDeleteItem(item)}
              style={{ cursor: "pointer" }}
            >
              delete_outline
            </i>
          ) : null}
        </div>
      </ListGroupItem>
    </Flipped>
  );
};

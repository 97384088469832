import React from "react";
import { Lottie } from "@crello/react-lottie";
import animationData from "../animations/fib_lottie.json";

const LottieLoader = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  // return <Lottie config={defaultOptions} height={260} width={670} />;
  return <Lottie config={defaultOptions} width={600} />;
};

export default LottieLoader;

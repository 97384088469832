import { useEffect, useReducer } from "react";
import * as queries from "../graphql/queries";
import * as subscriptions from "../graphql/subscriptions";
import API, { graphqlOperation } from "@aws-amplify/api";
import sortBy from "lodash.sortby";

const initialState = { indexes: [] };

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_INDEXES":
      return { indexes: action.payload };

    case "ADD_INDEX":
      return { indexes: [...state.indexes, action.payload] };

    case "DELETE_INDEX":
      return {
        indexes: state.indexes.filter((item) => item.id !== action.payload.id)
      };

    case "UPDATE_INDEX":
      console.log(action.payload);
      return {
        indexes: state.indexes.map((item) => {
          if (item.id === action.payload.id) {
            // TODO:  This is a hack because, for some reason, owner is lost when receiving a subscription
            return { ...action.payload, owner: item.owner };
          } else {
            return item;
          }
        })
      };

    default:
      break;
  }
};

export const useSubscription = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    API.graphql(graphqlOperation(queries.listFibIndexs)).then(
      (fibIndexesData) => {
        dispatch({
          type: "SET_INDEXES",
          payload: sortBy(
            fibIndexesData.data.listFibIndexs.items,
            (item) => item.index
          )
        });
      }
    );
  }, []);

  // createFibIndex subscription
  useEffect(() => {
    const subscription = API.graphql(
      graphqlOperation(subscriptions.onCreateFibIndex)
    ).subscribe({
      next: (data) => {
        console.log(
          "Created Subscription Item: ",
          data.value.data.onCreateFibIndex
        );
        const {
          value: {
            data: { onCreateFibIndex }
          }
        } = data;
        dispatch({ type: "ADD_INDEX", payload: onCreateFibIndex });
      }
    });
    return () => subscription.unsubscribe();
  }, []);

  // deleteFibIndex subscription
  useEffect(() => {
    const subscription = API.graphql(
      graphqlOperation(subscriptions.onDeleteFibIndex)
    ).subscribe({
      next: (data) => {
        console.log(
          "Deleted Subscription Item: ",
          data.value.data.onDeleteFibIndex
        );
        const {
          value: {
            data: { onDeleteFibIndex }
          }
        } = data;
        dispatch({ type: "DELETE_INDEX", payload: onDeleteFibIndex });
      }
    });
    return () => subscription.unsubscribe();
  }, []);

  // updateFibIndex subscription
  useEffect(() => {
    const subscription = API.graphql(
      graphqlOperation(subscriptions.onUpdateFibIndex)
    ).subscribe({
      next: (data) => {
        console.log(
          "Updated Subscription Item: ",
          data.value.data.onUpdateFibIndex
        );
        const {
          value: {
            data: { onUpdateFibIndex }
          }
        } = data;
        dispatch({ type: "UPDATE_INDEX", payload: onUpdateFibIndex });
      }
    });
    return () => subscription.unsubscribe();
  }, []);

  return state.indexes;
};

import { FibFinderList } from "./FibFinderList";
import Loading from "./Loading";
import {
  Button,
  Form,
  FormGroup,
  FormText,
  Input,
  Spinner,
  Label
} from "reactstrap";
import * as mutations from "../graphql/mutations";
import API, { graphqlOperation } from "@aws-amplify/api";
import React, { useState } from "react";
import { useSubscription } from "../hooks/useSubscription";

const FibFinder = ({ currentUser }) => {
  const [error, setError] = useState("");
  const [indexInput, setIndexInput] = useState("");
  const [finding, setIsFinding] = useState(false);

  const indexes = useSubscription();

  const handleCreateIndex = async (e) => {
    e.preventDefault();
    setError(false);
    if (indexInput < 1 || indexInput > 102) {
      setError("Only numbers 1 through 102 are allowed.");
      return;
    }
    const usedIndexes = indexes.map((item) => parseInt(item.index));
    if (usedIndexes.includes(parseInt(indexInput))) {
      setError("That number has already been found.");
      return;
    }
    setIsFinding(true);
    try {
      const response = await API.graphql(
        graphqlOperation(mutations.createFibIndex, {
          input: { index: indexInput, value: "calculating..." }
        })
      );
      console.log("Create index response", response);
      setIndexInput("");
      setIsFinding(false);
    } catch (e) {
      setIsFinding(false);
      console.log(e);
    }
  };

  const handleClearPersistedData = async () => {
    const deleteItem = async (id) => {
      try {
        const response = await API.graphql(
          graphqlOperation(mutations.deleteFibIndex, {
            input: { id }
          })
        );
        return response;
      } catch (e) {
        console.log(e);
      }
    };

    const pendingDelete = indexes.map((item) => deleteItem(item.id));
    console.log("Awaiting URL promises...");
    const deletePromises = await Promise.all(pendingDelete);
    console.log("Promises fulfilled!", deletePromises);
    // setIndexes([]);
  };

  // const listIndexesQuery = async () => {
  //   console.log("listing indexes");
  //   try {
  //     const { data } = await API.graphql(
  //       graphqlOperation(queries.listFibIndexs)
  //     );
  //     setIndexes(sortBy(data.listFibIndexs.items, (item) => item.index));
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  // useEffect(() => {
  //   listIndexesQuery();
  // }, []);

  // create subscription
  // moved to custom hook
  // useEffect(() => {
  //   const subscription = API.graphql(
  //     graphqlOperation(subscriptions.onCreateFibIndex)
  //   ).subscribe({
  //     next: (fibIndexData) => {
  //       console.log(
  //         "Subscription New Index: ",
  //         fibIndexData.value.data.onCreateFibIndex
  //       );
  //       const newItem = fibIndexData.value.data.onCreateFibIndex;
  //       // const updatedItems = indexes.concat(newItem);
  //       setIndexes(sortBy(indexes.concat(newItem), (item) => item.index));
  //     }
  //   });
  //   return () => subscription.unsubscribe();
  // }, [indexes]);

  // update subscription

  // useEffect(() => {
  //   const items = indexes;
  //   const subscription = API.graphql(
  //     graphqlOperation(subscriptions.onUpdateFibIndex)
  //   ).subscribe({
  //     next: (fibIndexData) => {
  //       console.log(
  //         "Subscription Updated Index",
  //         fibIndexData.value.data.onUpdateFibIndex
  //       );
  //       const newItem = fibIndexData.value.data.onUpdateFibIndex;
  //       const index = findIndex(items, { id: newItem.id });
  //       const existingItem = items[index];
  //       newItem.owner = existingItem.owner;
  //       newItem.new = true;
  //       sortBy(items.splice(index, 1, newItem), (item) => item.index);
  //       setIndexes([...items]);
  //     }
  //   });
  //   return () => subscription.unsubscribe();
  // }, [indexes]);

  // delete subscription
  // useEffect(() => {
  //   const items = indexes;
  //   const subscription = API.graphql(
  //     graphqlOperation(subscriptions.onDeleteFibIndex)
  //   ).subscribe({
  //     next: (fibIndexData) => {
  //       console.log(
  //         "Subscription Delete Index",
  //         fibIndexData.value.data.onDeleteFibIndex
  //       );
  //       const deletedItem = fibIndexData.value.data.onDeleteFibIndex;
  //       const updatedItems = items.filter((item) => item.id !== deletedItem.id);
  //       sortBy(updatedItems, (item) => item.index);
  //       setIndexes(updatedItems);
  //       // TODO:  remove this when you figure out how to make things work without it!
  //       listIndexesQuery();
  //     }
  //   });
  //   return () => subscription.unsubscribe();
  // }, [indexes]);

  return (
    <div className="container mt-2" style={{ maxWidth: "640px" }}>
      <Form onSubmit={handleCreateIndex}>
        <FormGroup className="mb-0">
          <div className="d-flex d-sm-none">
            <Label for="fib-input" size="sm" className="mb-1">
              Enter a Number Between 0 and 103
            </Label>
          </div>
          <div className="d-none d-sm-flex">
            <Label for="fib-input">Enter a Number Between 0 and 103</Label>
          </div>
          <div className="d-flex d-sm-none">
            <Input
              autoFocus
              className="mb-1"
              type="number"
              name="fib-input"
              id="fib-input"
              onChange={(e) => setIndexInput(e.target.value)}
              bsSize="sm"
              style={{ width: "100%" }}
              value={indexInput}
            />
          </div>
          <div className="d-none d-sm-flex">
            <Input
              autoFocus
              type="number"
              name="fib-input"
              id="fib-input"
              onChange={(e) => setIndexInput(e.target.value)}
              style={{ width: "100%" }}
              value={indexInput}
            />
          </div>
          {error ? <FormText color="danger">{error}</FormText> : null}
        </FormGroup>
        {indexes.length <= 9 ? (
          <>
            <div className="d-flex d-sm-none mt-1">
              <Button
                block
                className="d-flex align-items-center justify-content-center mb-1"
                color="warning"
                disabled={indexes.length >= 10 || !indexInput}
                size="sm"
              >
                {finding ? (
                  <Spinner className="mr-1" size="sm" color="secondary" />
                ) : (
                  <i className="material-icons">search</i>
                )}
                <span className="ml-2">Find Fibonacci</span>
              </Button>
            </div>
            <div className="d-none d-sm-flex mt-2">
              <Button
                block
                className="d-flex align-items-center justify-content-center mb-3"
                color="warning"
                disabled={indexes.length >= 10 || !indexInput}
              >
                {finding ? (
                  <Spinner className="mr-1" size="sm" color="secondary" />
                ) : (
                  <i className="material-icons">search</i>
                )}
                <span className="ml-2">Find Fibonacci</span>
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className="d-flex d-sm-none">
              <Button
                className="mb-1"
                block
                color="danger"
                outline
                onClick={handleClearPersistedData}
                size="sm"
              >
                Clear Persisted Data
              </Button>
            </div>
            <div className="d-none d-sm-flex mt-2">
              <Button
                className="mb-3"
                block
                color="danger"
                outline
                onClick={handleClearPersistedData}
              >
                Clear Persisted Data
              </Button>
            </div>
          </>
        )}
      </Form>
      {indexes.length ? (
        <FibFinderList
          indexes={indexes}
          // setIndexes={setIndexes}
          currentUser={currentUser}
        />
      ) : (
        <div className="d-none d-sm-flex">
          <Loading />
        </div>
      )}
    </div>
  );
};

export default FibFinder;

import React from "react";
import arch from "../images/cloud-arch.png";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const ArchModal = ({ modalIsOpen, setModalIsOpen }) => {
  return (
    <div>
      <Modal size="lg" isOpen={modalIsOpen}>
        <ModalHeader className="lead">Architecture</ModalHeader>
        <ModalBody className="text-left">
          <p>
            This simple app demonstrates a decoupled architecture, using AWS
            services.
          </p>
          <p>AppSync facilitates graphQL operations on a DynamoDB table.</p>
          <p>
            A Lambda function is triggered by a DynamoDB stream when changes
            occur in the table.
          </p>
          <p>
            The Lambda function processes the stream and updates items in the
            DynamoDB table with calculated values.
          </p>
          <div className="text-center container">
            <img
              className="mt-3"
              src={arch}
              alt="architecture diagram"
              style={{ maxWidth: "90%" }}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            className="text-muted"
            color="link"
            onClick={() => setModalIsOpen(false)}
          >
            close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ArchModal;
